.md blockquote {
  font-family: 'Inter';
  background-color: rgba(148, 148, 149, 0.08) ;
  margin: 1.5em 0px;
  padding: 1.1em 20px 1px 20px;
  border-left: 8px solid var(--pm);
  font-style: italic;
}

.md table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 1em 0;
  overflow: auto;
}

.md table th {
  background-color: var(--bg);
}

.md table th, table td {
  padding: 10px 20px;
  border: 1px solid var(--bd);
}

.md img {
  width: 100%;
  max-width: 100%;
}

.md li {
  margin-top: .5em;
}

.md .katex {
  overflow: auto hidden;
}